import React from 'react';

import { PRIORITY } from 'wix-ui-tpa/Button';
import { Text, TYPOGRAPHY } from 'wix-ui-tpa/Text';

import { st, classes } from './DeleteGroupRequestModal.st.css';
import { withTranslation, WithTranslation } from '@wix/yoshi-flow-editor';
import { Modal } from '../../../../../common/components/Modal/Modal';
import { withTheme, Theme } from '../../../../../common/context/theme';
import { Button } from '../../Button/Button';

export interface DeclineGroupRequestModalProps {
  onConfirmGroupDeletion(e: any): void;
  onRequestClose(e: any): void;
}

export function DeleteGroupRequestModalComponent(
  props: WithTranslation & DeclineGroupRequestModalProps & Theme,
) {
  const {
    onRequestClose,
    onConfirmGroupDeletion,
    t,
    forceBlackAndWhite,
  } = props;

  return (
    <div className={st(classes.root, { bw: !!forceBlackAndWhite })}>
      <Modal.Header title={t('groups-web.modal.delete-group-request.title')} />
      <Text typography={TYPOGRAPHY.runningText} className={classes.subtitle}>
        {t('groups-web.modal.delete-group-request.subtitle')}
      </Text>
      <Modal.Buttons>
        <Button
          forceBlackAndWhite={forceBlackAndWhite}
          priority={PRIORITY.secondary}
          onClick={onRequestClose}
        >
          {t('groups-web.modal.decline-group.action.cancel')}
        </Button>
        <Button
          forceBlackAndWhite={forceBlackAndWhite}
          type="submit"
          priority={PRIORITY.primary}
          onClick={onConfirmGroupDeletion}
        >
          {t('groups-web.modal.decline-group.action.delete')}
        </Button>
      </Modal.Buttons>
    </div>
  );
}

export const DeleteGroupRequestModal = withTheme(
  withTranslation()(DeleteGroupRequestModalComponent),
) as React.ComponentType<DeclineGroupRequestModalProps>;
