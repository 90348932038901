import React from 'react';
import { DropdownOptionProps } from 'wix-ui-tpa/Dropdown';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';
import { AppRootActions } from '../App/types';
import { DATA_HOOKS } from './dataHooks';
import { compose } from '../../../../common/utils/compose';
import {
  InjectedBiLoggerProps,
  InjectedErrorMonitorProps,
  withBi,
  withErrorMonitor,
  WithTranslation,
  withTranslation,
} from '@wix/yoshi-flow-editor';
import { LabeledDropdown } from '../../../../common/components/LabeledDropdown/LabeledDropdown';
import { BIUserEntry } from '../../../../common/bi-logger/types';
import { groupsChoseGroupsSortBy } from '@wix/bi-logger-groups/v2';

interface SortingBoxProps {
  onChange: AppRootActions['sortGroups'];
  initialSelectedId: string;
  className?: string;
  mobile?: boolean;
}

const sortByKeys: { [key in ApiTypes.v1.GroupsSortBy]: string } = {
  [ApiTypes.v1.GroupsSortBy.RECENT_ACTIVITY]:
    'groups-web.group-list.sort.recent-activity',
  [ApiTypes.v1.GroupsSortBy.NAME]: 'groups-web.group-list.sort.alphabetical',
  [ApiTypes.v1.GroupsSortBy.MEMBERS_COUNT]:
    'groups-web.group-list.sort.member-count',
};

type Props = SortingBoxProps &
  InjectedBiLoggerProps &
  InjectedErrorMonitorProps &
  WithTranslation;

class SortByComponent extends React.Component<Props> {
  onChange = (option: DropdownOptionProps) => {
    try {
      this.props.bi.report(
        groupsChoseGroupsSortBy({
          sort_by: option.id,
          origin: 'popup_menu',
          userEntry: BIUserEntry.SITE,
        }),
      );

      this.props.onChange(option.id as ApiTypes.v1.GroupsSortBy);
    } catch (e) {
      console.warn('No `onChange` in props');
      this.props.errorMonitor.captureException(e);
    }
  };
  private options!: DropdownOptionProps[];

  render() {
    const { t, className } = this.props;
    const options = this.getOptions();
    const label = t('groups-web.group-list.sort');
    // dropdown is not updated on props.initialSelectedId https://github.com/wix/wix-ui-tpa/pull/356
    return (
      <LabeledDropdown
        label={label}
        options={options}
        data-hook={DATA_HOOKS.sortDropdown}
        onChange={this.onChange}
        initialSelectedId={this.props.initialSelectedId}
        className={className}
      />
    );
  }

  private getOptions() {
    const { t } = this.props;
    if (t && !this.options) {
      this.options = Object.keys(sortByKeys).map((id) => ({
        id,
        value: t((sortByKeys as any)[id]),
        isSelectable: true,
      }));
    }
    return this.options;
  }
}

const enhance = compose(withTranslation(), withBi, withErrorMonitor);

export const SortBy = enhance(
  SortByComponent,
) as React.ComponentType<SortingBoxProps>;
